.clients-icons-wrapper div {
  height: 80px;
  width: 140px;
  background: white;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.clients-icons-wrapper div:nth-child(1) {
  top: 22%;
  left: 28%;
  /* border: 2px solid red; */
}
.clients-icons-wrapper div:nth-child(2) {
  top: 8%;
  left: 45%;
  /* border: 2px solid red; */
}
.clients-icons-wrapper div:nth-child(3) {
  top: 45%;
  left: 75%;
  /* border: 2px solid red; */
}
.clients-icons-wrapper div:nth-child(4) {
  top: 72%;
  left: 62%;
  /* border: 2px solid red; */
}
.clients-icons-wrapper div:nth-child(5) {
  top: 22%;
  left: 65%;
  /* border: 2px solid red; */
}
.clients-icons-wrapper div:nth-child(6) {
  top: 72%;
  left: 30%;
  /* border: 2px solid red; */
}
.clients-icons-wrapper div:nth-child(7) {
  top: 45%;
  left: 20%;
  /* border: 2px solid red; */
}
.clients-icons-wrapper div:nth-child(8) {
  top: 40%;
  left: 5%;
}
.clients-icons-wrapper div:nth-child(9) {
  top: 8%;
  left: 78%;
}
.clients-icons-wrapper div:nth-child(12) {
  top: 55%;
  left: 90%;
}
.clients-icons-wrapper div:nth-child(11) {
  top: 20%;
  left: 90%;
}
.clients-icons-wrapper div:nth-child(10) {
  top: 7%;
  left: 18%;
}
.clients-icons-wrapper div:nth-child(13) {
  top: 80%;
  left: 10%;
}
.clients-icons-wrapper div:nth-child(14) {
  top: 85%;
  left: 45%;
}
.clients-icons-wrapper div:nth-child(15) {
  top: 80%;
  left: 80%;
}

.application-expertise-swiper-active {
  /* width: 650px !important; */
  transform: scale(1);
  transition: width 0.5s linear;
  height: auto;
}

@media (max-width: 1366px) {
  .clients-icons-wrapper div {
    height: 60px;
    width: 120px;
  }

  .application-expertise-swiper-active {
    transform: scale(1);
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .application-expertise-swiper-active {
    /* width: 600px !important; */
    transform: scale(1);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .application-expertise-swiper-active {
    /* width: 520px !important; */
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .application-expertise-swiper-active {
    /* width: auto !important; */
    transform: scale(1);
  }
}
