/*  homepage our people section */

.homepage .text-wrapper-active h2 {
  animation: slideandappear 300ms ease-in-out forwards;
}

.homepage .text-wrapper-active h3 {
  animation: slideandappear 300ms ease-in-out 80ms forwards;
}

.homepage .text-wrapper-active .btn-wrapper {
  animation: slideandappear 300ms ease-in-out 150ms forwards;
}

.homepage .people-wrapper-active .tile {
  animation: appear 300ms ease-in-out forwards;
}

.homepage .text-wrapper-hide h2 {
  animation: slideandhide 300ms ease-in-out forwards;
}

.homepage .text-wrapper-hide h3 {
  animation: slideandhide 300ms ease-in-out 80ms forwards;
}

.homepage .text-wrapper-hide .btn-wrapper {
  animation: slideandhide 300ms ease-in-out 150ms forwards;
}

.homepage .people-wrapper-hide .tile {
  animation: appear 300ms ease-in-out forwards;
}

.homepage .our-people .aside-wrapper-right,
.homepage .our-people .aside-wrapper-left {
  grid-template-rows: repeat(6, 20%);
}

.homepage .possibilities-wrapper .swiper-slide:after {
  content: '';
  position: absolute;
  inset: 0;
  transition: opacity 300ms ease-in-out;
  opacity: 0.6;
  background: #000000;
}

.homepage .possibilities-wrapper .swiper-slide-active:after {
  opacity: 0;
}
/* Solution wrapper */

.activeTabClassName {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  line-height: 0;
  font-weight: 400;
  opacity: 0.6;
}

.inactiveTabClassName {
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  line-height: 0;
  font-weight: 400;
  opacity: 0.6;
}

.our-people-wrapper .bg-gradient {
  background: linear-gradient(
    90deg,
    #0067b1 26.28%,
    rgba(0, 103, 177, 0.92) 38.81%,
    rgba(0, 103, 177, 0) 62.46%
  );
}

.our-people-wrapper .swiper-wrapper .swiper-slide {
  -webkit-transition: 500ms -webkit-filter linear;
  transition: transform 2s linear;
}

.journey-wrapper .bg-gradient {
  background: linear-gradient(
    90deg,
    #0067b1 20.28%,
    rgba(0, 103, 177, 0.92) 40.81%,
    rgba(0, 103, 177, 0) 90.46%
  );
}

@media (max-width: 1023px) {
  .our-people-wrapper .bg-gradient {
    background: linear-gradient(
      90deg,
      #0067b1 26.28%,
      rgba(0, 103, 177, 0.92) 40.81%,
      rgba(0, 103, 177, 0) 62.46%
    );
  }
}

@media (max-width: 767px) {
  .journey-wrapper .bg-gradient {
    background: linear-gradient(
      360deg,
      rgba(0, 103, 177, 0.92) 10.81%,
      rgba(0, 103, 177, 0) 90%
    );
  }
  .our-people-wrapper .bg-gradient {
    background: linear-gradient(
      360deg,
      #0067b1 26.28%,
      rgba(0, 103, 177, 0.92) 48.81%,
      rgba(0, 103, 177, 0) 62.46%
    );
  }
}

/* text-white flex eles-center justify-end gap-3 leading-none font-normal opacity-60 */
