@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  scroll-behavior: smooth;
  background: black;
  font-family: 'Manrope', sans-serif;
}

@layer components {
  .d {
    @apply border-2 border-[#ff0000] border-solid;
  }

  .blade-top-padding {
    @apply pt-8 md:pt-10 lg:pt-12 xlg:pt-16 2xl:pt-20;
    /* needs to be changed*/
  }

  .blade-top-padding-sm {
    @apply pt-4 md:pt-6 lg:pt-8 xl:pt-10 2xl:pt-14;
  }

  .blade-top-padding-lg {
    @apply pt-10 md:pt-12 lg:pt-20 xl:pt-24 2xl:pt-28;
  }

  .blade-top-padding-xl {
    @apply pt-16 md:pt-14 lg:pt-20 xl:pt-24 2xl:pt-32;
  }

  .blade-bottom-padding {
    @apply pb-8 md:pb-10 lg:pb-12 xlg:pb-16 2xl:pb-20;
    /* needs to be changed*/
  }

  .blade-bottom-padding-sm {
    @apply pb-4 md:pb-6 lg:pb-8 xl:pb-10 2xl:pb-14;
    /* needs to be changed*/
  }

  .blade-bottom-padding-lg {
    @apply pb-10 md:pb-12 lg:pb-16 xl:pb-20 2xl:pb-28;
    /* needs to be changed*/
  }

  .blade-bottom-padding-xl {
    @apply pb-16 md:pb-10 lg:pb-20 xl:pb-24 2xl:pb-32;
    /* needs to be changed*/
  }

  .blade-top-margin {
    @apply mt-8 md:mt-10 lg:mt-12 xl:mt-16 2xl:mt-20;
  }

  .blade-top-margin-sm {
    @apply mt-4 md:mt-6 lg:mt-8 xl:mt-10 2xl:mt-14;
  }

  .blade-top-margin-lg {
    @apply mt-10 md:mt-12 lg:mt-16 xl:mt-20 2xl:mt-24;
  }

  .blade-bottom-margin {
    @apply mb-8 md:mb-10 lg:mb-12 xl:mb-16 2xl:mb-20;
  }

  .blade-bottom-margin-sm {
    @apply mb-4 md:mb-6 lg:mb-8 xl:mb-10 2xl:mb-14;
  }

  .blade-bottom-margin-lg {
    @apply mb-10 md:mb-12 lg:mb-16 xl:mb-20 2xl:mb-24;
  }

  .w-container-xs {
    @apply max-w-screen-xl mx-auto w-11/12 md:w-8/12 sm:px-3;
  }

  .w-container-sm {
    @apply max-w-screen-xl mx-auto w-11/12 md:w-10/12 sm:px-3;
  }

  .w-container {
    @apply max-w-screen-2xl mx-auto md:w-11/12 lg:w-10/12 px-3;
  }

  .w-container-lg {
    @apply md:w-11/12 px-3 max-w-screen-2xl mx-auto;
  }

  .w-container-xl {
    @apply md:w-11/12 px-3 xl:px-16 2xl:px-20 max-w-[1920px] mx-auto;
  }

  .text-big {
    @apply 2xl:text-8xl xl:text-7xl text-4xl;
  }

  .input-style {
    @apply !bg-transparent 2xl:text-lg xlg:text-sm text-[12px] focus-visible:bg-transparent focus:bg-transparent placeholder:text-dark focus-within:bg-transparent focus:outline-none font-normal text-black border-b-[1px] border-[#3A3D45] w-full xlg:py-3 py-2;
  }

  .input-style-light {
    @apply !bg-transparent 2xl:text-lg xlg:text-sm text-[12px] focus-visible:bg-transparent focus:bg-transparent placeholder:opacity-90 focus-within:bg-transparent focus:outline-none font-normal text-white text-opacity-70 border-b-[1px] border-[#3A3D45] w-full xlg:py-2 py-2;
  }
}

@layer base {
  .text-big {
    @apply text-8xl;
  }

  h1 {
    @apply text-[28px] lg:text-[30px] xl:text-[36px] 2xl:text-4xl leading-snug lg:leading-snug 2xl:leading-snug;
  }

  h2 {
    @apply text-[28px] lg:text-[30px] xl:text-[36px] 2xl:text-4xl leading-snug lg:leading-snug 2xl:leading-snug;
  }
  .text-42 {
    @apply text-[18px] lg:text-[24px] xl:text-[28px] 2xl:text-[42px] leading-snug lg:leading-snug 2xl:leading-snug;
  }

  h3 {
    @apply text-2xl lg:text-2xl xlg:text-[28px] 2xl:text-[32px] leading-snug md:leading-snug lg:leading-snug 2xl:leading-snug;
  }

  h4 {
    @apply text-base lg:text-lg xlg:text-lg 2xl:text-2xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }

  h5 {
    @apply text-sm xl:text-base 2xl:text-xl leading-normal md:leading-normal lg:leading-normal 2xl:leading-normal;
  }

  h6 {
    @apply text-sm lg:text-base leading-tight md:leading-snug lg:leading-snug 2xl:leading-snug;
  }

  p {
    @apply text-sm leading-tight md:leading-snug lg:leading-snug 2xl:leading-snug;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #00bfff;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}

::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

/* remove blue active state on mobile*/
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.swiper-pagination .swiper-pagination-bullet {
  border-radius: 99999px;
}

/*  remove rounding of the input element on IOS */
input[type='search'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}

input,
textarea {
  border-radius: 0;
}

.webkit-rounded {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  transform: translateZ(0);
}

dialog::backdrop {
  height: 100vh;
  width: 100vw;
  background-color: cyan !important;
}
