@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes bounce {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(2);
  }
}
.active::after {
  content: '';
  position: absolute;
  z-index: 10;
  height: 0px;
  width: 0px;
  background: red;

  /* animation: bounce 0.3s ease-in-out infinite alternate; */
}

.fade-in {
  animation: appear 300ms ease-in-out forwards;
}
