.customers-pagination-wrapper .swiper-pagination-bullet {
  border: 1px solid #00bfff !important;
  opacity: 0.6 !important;
  height: 14px;
  width: 14px;
  background-color: transparent !important;
}

.customers-pagination-wrapper .swiper-pagination-bullet-active {
  background-color: #00bfff !important;
  opacity: 1 !important;
}

/* .who-we-are .ecosystem-wrapper {
  background-image: linear-gradient(
    to right,
    #ebf6ff,
    #ebf6ff 52%,
    white 52%,
    white
  );
} */

@media screen and (max-width: 768px) {
  .customers-pagination-wrapper .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
  }

  /* .who-we-are .ecosystem-wrapper {
    background-image: linear-gradient(to right, #ebf6ff, #ebf6ff 100%);
  } */
}

.who-we-are .ecosystem-carousel .swiper-wrapper {
  transition-timing-function: linear;
}

/* -------------- our journey section css ------------- */

.our-journey .slideDescWrapper-enter {
  opacity: 0;
  transform: translateY(10px);
}

.our-journey .slideDescWrapper-enter-active {
  transition-property: opacity, transform;
  transform: translateY(0);
  transition-duration: 500ms;
  -webkit-transition-property: opacity, transform;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 500ms;
  -moz-transition-property: opacity, transform;
  -moz-transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  -o-transition-property: opacity, transform;
  -o-transition-duration: 500ms;
  opacity: 1;
}

.our-journey .slideDescWrapper-exit {
  transform: translateY(0px);
  opacity: 1;
}

.our-journey .slideDescWrapper-exit-active {
  transition-property: opacity, transform;
  transform: translateY(10px);
  transition-duration: 300ms;
  -webkit-transition-property: opacity, transform;
  -webkit-transition-duration: 300ms;
  -moz-transition-property: opacity, transform;
  -moz-transition-duration: 300ms;
  -o-transition-property: opacity, transform;
  -o-transition-duration: 300ms;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  opacity: 0;
}

/* .our-journey .wrap > * {
  animation: appearX 300ms ease;
  opacity: 1;
  transform: translateX();
} */

.values-container:hover .values-circle-blue {
  background-color: #00aeef12;
  border: 1px solid #00aeef;
}
.values-container:hover .values-circle-yellow {
  background-color: #ffdc2412;
  border: 1px solid #ffdc24;
}
.values-container:hover .values-circle-green {
  background-color: #00e8da12;
  border: 1px solid #00e8da;
}
.values-container:hover .values-circle-purple {
  background-color: #4900e612;
  border: 1px solid #4900e6;
}
.values-container:hover .values-circle-orange {
  background-color: #e88b0012;
  border: 1px solid #e88b00;
}

@keyframes slideandappear {
  from {
    transform: translateY(10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideandhide {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(16px);
    opacity: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes appearX {
  from {
    opacity: 0;
    transform: translateX(0px);
  }

  to {
    opacity: 1;
    transform: translateX(10px);
  }
}
