.marquee {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  /* animation: scroll-x 150s linear infinite; */
}

.marquee-reverse {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  /* animation: scroll-x-reverse 160s linear infinite; */
}

@media (max-width: 768px) {
  .marquee-reverse {
    animation: scroll-x-reverse 50s linear infinite;
  }

  .marquee {
    animation: scroll-x 40s linear infinite;
  }
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 2rem));
  }
}

@keyframes scroll-x-reverse {
  from {
    transform: translateX(calc(-100% - 2rem));
  }

  to {
    transform: translateX(0);
  }
}

@media (max-width: 786px) {
}
