.dropdown {
  position: absolute;
  top: 100%;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
}

/* casestudies carouisel */
.values-carousel .swiper-pagination {
  width: fit-content;
  /* background-color: #252525; */
}

.slide {
  position: absolute;
  inset: 0;
  font-size: 2rem;
}

.slide1 {
  opacity: 0;
}

.slide2 {
  opacity: 0;
}

.slide3 {
  opacity: 0;
}

.background-gradient-img {
  position: relative;
  z-index: 1;
}

/* Carousal description section animation */

.slideDescWrapper-enter {
  opacity: 0;
  transform: translateY(50px);
}

.slideDescWrapper-enter-active {
  transition-property: opacity, transform;
  transform: translateY(0);
  transition-duration: 500ms;
  -webkit-transition-property: opacity, transform;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 500ms;
  -moz-transition-property: opacity, transform;
  -moz-transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  -o-transition-property: opacity, transform;
  -o-transition-duration: 500ms;
  opacity: 1;
}

.slideDescWrapper-exit {
  transform: translateY(0);
  opacity: 1;
}

.slideDescWrapper-exit-active {
  transition-property: opacity, transform;
  transform: translateY(-50px);
  transition-duration: 500ms;
  -webkit-transition-property: opacity, transform;
  -webkit-transition-duration: 500ms;
  -moz-transition-property: opacity, transform;
  -moz-transition-duration: 500ms;
  -o-transition-property: opacity, transform;
  -o-transition-duration: 500ms;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  opacity: 0;
}

@keyframes slideandappear {
  from {
    transform: translateY(16px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideandhide {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(16px);
    opacity: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* ==================================================================== ========================================================= ================================================================================================ */

/* caseStudiescarosuel */

.case-studies-organism .swiper-wrapper .swiper-slide {
  padding: 10px;
  -webkit-transition: 500ms -webkit-filter linear;
  transition: opacity 500ms ease;
}

.slideActiveClass {
  opacity: 1 !important;
  filter: blur(0) !important;
}

.slideNextClass {
  opacity: 0.5 !important;
  filter: blur(2px) !important;
}

.slidePrevClass {
  opacity: 0.5 !important;
  filter: blur(3px) !important;
}

.swiper-pagination .swiper-pagination-bullet {
  background: rgba(0, 174, 239, 0.35) !important;
  opacity: 1 !important;
  height: 10px !important;
  width: 10px;
  transition: all 500ms ease-in-out;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #00bfff !important;
  width: 30px !important;
  height: 10px !important;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .swiper-pagination .swiper-pagination-bullet {
    height: 7px !important;
    width: 7px;
  }
  .swiper-pagination .swiper-pagination-bullet-active {
    height: 7px !important;
  }
}

/* Gradient Overlay */

.gradient-overlay .bgImage::before {
  content: '';
  position: absolute;
  inset: 0;
  background: #0000007b;
}
