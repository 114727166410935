/* @media (max-width: 766px) {
  .certifications-wrapper .pointers-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
} */

@media (min-width: 767px) {
  .certifications-wrapper .pointers-wrapper > * {
    grid-column: span 2;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  .certifications-wrapper .pointers-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .certifications-wrapper .pointers-wrapper > *:last-child:nth-child(odd) {
    grid-column-end: 4;
  }
}

@media (min-width: 1024px) {
  .certifications-wrapper .pointers-wrapper {
    grid-template-columns: repeat(6, 1fr);
  }

  .certifications-wrapper .pointers-wrapper > *:last-child:nth-child(3n-2) {
    grid-column-end: 5;
  }

  .certifications-wrapper .pointers-wrapper > *:last-child:nth-child(3n-1) {
    grid-column-end: 6;
  }

  .certifications-wrapper
    .pointers-wrapper
    > *:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
  }

  .certifications-wrapper
    .pointers-wrapper
    > *:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: 4;
  }
}

@media (min-width: 1440px) {
  .certifications-wrapper .pointers-wrapper {
    grid-template-columns: repeat(5, 1fr);
  }

  .certifications-wrapper .pointers-wrapper > * {
    grid-column-start: auto !important;
    grid-column-end: auto !important;
  }
}

.apparel-page .swiper-slide {
  height: auto !important;
}

.appearal-text-slide .swiper-slide {
  transition-property: opacity, transform !important;
  transition-duration: 500ms, 500ms !important;
}

.appearal-text-slide .swiper-slide-active span {
  opacity: 1 !important;
  scale: 1 !important;
}

.quality-wrapper {
  background-image: url('../../assets/apparel/bgLeft.png'),
    url('../../assets/apparel/bgLeft.png');
  background-repeat: no-repeat;
  background-size: auto 100%, auto 100%;
  background-position: left -15% center, right -10% center;
}

@media screen and (max-width: 768px) {
  .quality-wrapper {
    background-image: url('../../assets/apparel/bgLeftMobile.png');
    background-size: 100% auto;
    background-position: left -120px bottom -250px;
  }
}

.apparel-banner {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0.25)
    ),
    linear-gradient(to bottom, red, red);
  background-blend-mode: multiply, multiply;
}

.swiper-image-mask {
  mask-image: linear-gradient(
    90deg,
    rgba(34, 94, 222, 0) 0%,
    rgba(0, 0, 0, 1) 49%,
    rgba(0, 0, 0, 1) 51%,
    rgba(0, 44, 255, 0) 100%
  );
}
