.innovation-page .salientFeatureSlide-enter {
  opacity: 0;
  transform: translateY(50px);
}

.innovation-page .salientFeatureSlide-enter-active {
  transition-property: opacity, transform;
  transform: translateY(0);
  transition-duration: 500ms;
  -webkit-transition-property: opacity, transform;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -webkit-transition-duration: 500ms;
  -moz-transition-property: opacity, transform;
  -moz-transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  -o-transition-property: opacity, transform;
  -o-transition-duration: 500ms;
  opacity: 1;
}

.innovation-page .salientFeatureSlide-exit {
  transform: translateY(0);
  opacity: 1;
}

.innovation-page .salientFeatureSlide-exit-active {
  transition-property: opacity, transform;
  transform: translateY(-50px);
  transition-duration: 500ms;
  -webkit-transition-property: opacity, transform;
  -webkit-transition-duration: 500ms;
  -moz-transition-property: opacity, transform;
  -moz-transition-duration: 500ms;
  -o-transition-property: opacity, transform;
  -o-transition-duration: 500ms;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  opacity: 0;
}

@keyframes slideandappear {
  from {
    transform: translateY(16px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideandhide {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(16px);
    opacity: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.innovation-page .salient-features .swiper-slide::after {
  content: '';
  position: absolute;
  inset: 0;
  transition: opacity 300ms ease-in-out;
  opacity: 0.6;
  background: #000000;
}

.innovation-page .salient-features .swiper-slide-active::after {
  opacity: 0;
}

/*  ========================================================================= */

.innovation-page .salient-pointer-card-content,
.innovation-page .salient-pointer-card-img {
  border: 1.5px solid rgba(225, 225, 225, 0.63);
}

.innovation-page .salient-pointer-card-content {
  border-top-color: transparent;
}

.innovation-page .salient-pointer-card-img {
  border-bottom-color: transparent;
}
