.automotive .evolution-card {
  position: relative;
}

.automotive .evolution-card:nth-child(1) .content-wrapper {
  bottom: -20%;
  transition: all 0.5s ease-in-out;
}

.automotive .evolution-card:nth-child(2) .content-wrapper {
  bottom: -6%;
  transition: all 0.5s ease-in-out;
}

.automotive .content-wrapper ul {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.automotive .evolution-card:nth-child(1) .gradient {
  height: 20%;
  transition: height 0.5s ease-in-out;
  background: linear-gradient(to top, #000, transparent);
}

.automotive .evolution-card:nth-child(2) .gradient {
  height: 36%;
  transition: height 0.5s ease-in-out;
  background: linear-gradient(to top, #000, transparent);
}

.automotive .evolution-card:hover .gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #006abc 100%);
  height: 100%;
}

.automotive .evolution-card:hover:nth-child(1) .content-wrapper {
  bottom: 6%;
}

.automotive .evolution-card:hover:nth-child(2) .content-wrapper {
  bottom: 20%;
}

.automotive .evolution-card:hover .content-wrapper ul {
  opacity: 1;
}

.automotive .stages-wrapper .stats-wrapper {
  border-top: 1px solid #6d6d6d;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.26) 0%,
    rgba(0, 0, 0, 0) 142.32%
  );

  backdrop-filter: blur(12px);
}

.automotive .stages-wrapper .stats-wrapper .stats-text {
  background: linear-gradient(
    93deg,
    rgba(255, 255, 255, 0.5) -0.28%,
    #fff 52.62%,
    rgba(255, 255, 255, 0.5) 105.93%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.automotive-subfooter-bg {
  mix-blend-mode: hard-light;
}

.automotive .map-wrapper {
  background-image: url('../../assets/automotive/map-bg.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}

.automotive .use-cases-wrapper .swiper-slide:after {
  content: '';
  position: absolute;
  inset: 0;
  transition: opacity 300ms ease-in-out;
  opacity: 0.6;
  background: #000000;
}

.automotive .use-cases-wrapper .swiper-slide-active:after {
  opacity: 0;
}

@media (max-width: 1024px) {
  .automotive-subfooter-bg {
    mix-blend-mode: normal;
  }

  .automotive .map-wrapper {
    background-image: none;
  }

  .automotive .evolution-card:nth-child(1) .gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #006abc 100%);
    height: 100%;
  }

  .automotive .evolution-card:nth-child(2) .gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #006abc 100%);
    height: 100%;
  }

  .automotive .evolution-card:nth-child(1) .content-wrapper {
    bottom: 6%;
  }
  .automotive .evolution-card:nth-child(2) .content-wrapper {
    bottom: 8%;
  }
  .automotive .evolution-card:hover:nth-child(2) .content-wrapper {
    bottom: 8%;
  }
  .automotive .evolution-card .content-wrapper ul {
    opacity: 1;
  }
}

@media (max-width: 639px) {
}

/* !Transition for solution section */
.fade-show-enter {
  opacity: 0;
}

.fade-show-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}

.fade-show-exit {
  opacity: 1;
}

.fade-show-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}
