.careers-page .ring-wrapper {
  background-image: url('../../assets/careers/mind.png'),
    url('../../assets/careers/circle2.svg'),
    url('../../assets/careers/circle1.svg');
  background-size: 170px auto, min(550px, 88%) auto, min(450px, 70%) auto;
  background-position: center, center, center;
  background-repeat: no-repeat;
}
.careers-page .ring-wrapper-linkedin {
  background-image: url('../../assets/careers/circle2.svg'),
    url('../../assets/careers/circle1.svg');
  background-size: min(550px, 93%) auto, min(450px, 75%) auto;
  background-position: center, center;
  background-repeat: no-repeat;
}

/* .pointers-events .pointer-event:nth-child(1) {
  transform: rotateZ(51deg);
}
.pointers-events .pointer-event:nth-child(2) {
  transform: rotateZ(102.85deg);
}
.pointers-events .pointer-event:nth-child(3) {
  transform: rotateZ(154.28deg);
}
.pointers-events .pointer-event:nth-child(4) {
  transform: rotateZ(205.71deg);
}
.pointers-events .pointer-event:nth-child(5) {
  transform: rotateZ(257.14deg);
}
.pointers-events .pointer-event:nth-child(6) {
  transform: rotateZ(308.57deg);
}
.pointers-events .pointer-event:nth-child(7) {
  transform: rotateZ(360deg);
} */
