.wrapper {
  background: #282537;
  background-image: -webkit-radial-gradient(
    top,
    circle farthest-corner,
    #3c3b52 0%,
    #252233 80%
  );
  background-image: -moz-radial-gradient(
    top,
    circle farthest-corner,
    #3c3b52 0%,
    #252233 80%
  );
  background-image: -o-radial-gradient(
    top,
    circle farthest-corner,
    #3c3b52 0%,
    #252233 80%
  );
  background-image: radial-gradient(
    top,
    circle farthest-corner,
    #3c3b52 0%,
    #252233 80%
  );
}
